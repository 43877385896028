import scrollTop from '@/lib/common-service/scrollTop'
import {awaitWrap} from "@/page/distributor/train/front/common/unit";
import buyer_dom_manage_enquiryList from '@/lib/data-service/flight/buyer_dom_manage_enquiryList'
import buyer_dom_manage_enquiryCancel from '@/lib/data-service/flight/buyer_dom_manage_enquiryCancel'

export default {
    data() {
        return {
            loading: false,
            list: [],
            searchForm: {
                enqListStatus: 72,
                enqOrderNo: '',
                enqTimeBegin: '',
                enqTimeEnd: '',
                pnr: '',
                psgName: '',
                pageSize: 10,
                currentPage: 1,
                totalCount: 0
            },
            enqTime: [],
            aListTimer: null,
            dialogVisible: false,
            deferInitDataCompleted: '',
            recordCount: [
                {
                    status: 0,
                    statusTxt: '全部'
                },
                {
                    status: 71,
                    statusTxt: '待报价'
                },
                {
                    status: 72,
                    statusTxt: '已报价'
                },
                {
                    status: 76,
                    statusTxt: '已取消'
                },
                {
                    status: 73,
                    statusTxt: '已驳回'
                },
                {
                    status: 11,
                    statusTxt: '待支付'
                },
                {
                    status: 21,
                    statusTxt: '待出票'
                },
                {
                    status: 22,
                    statusTxt: '出票成功'
                },
                {
                    status: 23,
                    statusTxt: '出票失败'
                },
            ],
            activeName: 72
        };
    },
    methods: {
        //初始化
        async init() {
            this.activeName = 72;
            this.onReset();
        },

        //搜索
        onSearch() {
            this.getList();
        },

        //重置
        onReset() {
            this.searchForm = {
                enqListStatus: this.activeName,
                enqOrderNo: '',
                enqTimeBegin: '',
                enqTimeEnd: '',
                pnr: '',
                psgName: '',
                pageSize: 10,
                currentPage: 1
            };
            this.list = [];
            this.enqTime = [];
            this.dialogVisible = false;
            this.getList();
        },

        //换tab
        onChangeTab(pItem) {
            const __this = this;
            __this.searchForm.enqListStatus = pItem.status;
            __this.activeName = pItem.status;
            this.searchForm.currentPage = 1
            __this.onSearch();
        },

        //获取list
        async getList() {
            this.loading = true;
            let [err, res] = await awaitWrap(buyer_dom_manage_enquiryList(this.searchForm))
            if (err) {
                this.list = [];
                this.searchForm.totalCount = 0
                this.loading = false;
                return;
            }
            this.list = res.pageResult && res.pageResult.pageData ? res.pageResult.pageData : [];
            this.searchForm.totalCount = res.pageResult && res.pageResult.pageData ? res.pageResult.totalCount : 0;
            scrollTop(500);
            this.loading = false;
        },

        //改变询价日期
        onChangeEnqTime(val) {
            if (val) {
                this.searchForm.enqTimeBegin = val[0] + ' 00:00:00';
                this.searchForm.enqTimeEnd = val[1] + ' 23:59:59';
            } else {
                this.searchForm.enqTimeBegin = [];
                this.searchForm.enqTimeEnd = [];
            }
            this.onSearch();
        },

        //弹出取消询价单弹框
        showCancelDialog(enqOrderNo) {
            this.cancelOrderNo = enqOrderNo;
            this.dialogVisible = true;
        },

        //取消询价单
        async cancelOrder() {
            this.loading = true;
            let [err, res] = await awaitWrap(buyer_dom_manage_enquiryCancel({enqOrderNo: this.cancelOrderNo}));
            if (err) {
                this.$message({type: "warning", message: "取消询价单失败！"})
                this.dialogVisible = false;
                this.loading = false;
                return;
            }
            this.$message({type: "success", message: "取消询价单成功！"})
            this.dialogVisible = false;
            await this.getList();
            this.loading = false;
        },
        //跳转确认报价页面
        toOperate(enqOrderNo){
            this.$router.push({
                name: 'air-ticket-admin-enquiry',
                query: {
                    type: 'operate',
                    enqOrderNo
                }
            })
        },
        //跳转至详情页
        toDetails(enqOrderNo) {
            this.$router.push({
                name: 'air-ticket-admin-enquiry',
                query: {
                    type: 'detail',
                    enqOrderNo
                }
            })
        },

        //去支付
        toPay(orderNo) {
            this.$router.push({
                name: 'air-pay',
                query: {
                    ordersNo: orderNo
                }
            })
        },

        //换页
        onChangePage(pCurrentPage) {
            const __this = this;
            __this.searchForm.currentPage = pCurrentPage
            __this.getList();
        },

        //换每页最大返回条数
        onChangePageSize(pPageSize) {
            const __this = this;
            __this.searchForm.pageSize = pPageSize
            __this.getList();
        },
    },
    async mounted() {
        this.init();
    },
    activated() {

    }
}
